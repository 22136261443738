"use strict";


/**
 *
 * Weekend Discounts
 *
 * To have discounts over weekend we use Booqables coupon code feature and
 * automatically apply a coupon based on the start and end dates of the cart.
 *
 * We also differenciate between weekend discounts for trailers and the truck.
 *
 */

/*
We expect axious and momentjs to already be loaded
function loadJS (url, implementationCode, location) {
	//url is URL of external file, implementationCode is the code
	//to be called from the file, location is the location to
	//insert the <script> element

	var scriptTag = document.createElement('script');
	scriptTag.src = url;

	scriptTag.onload = implementationCode;
	scriptTag.onreadystatechange = implementationCode;

	location.appendChild(scriptTag);
};

loadJS('https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js', onDependencyLoaded, document.body);
loadJS('https://unpkg.com/axios/dist/axios.min.js', onDependencyLoaded, document.body);
*/

// execute our booqable coupon logic, as soon as all libraries are loaded
function onDependencyLoaded() {
	const store = window.booqableStore || window.store;
	if(window.moment && window.axios && store) {
		couponCodeWeekendLogic();
	} else {
		setTimeout(onDependencyLoaded, 300);
	}
}
setTimeout(onDependencyLoaded, 300);

function couponCodeWeekendLogic() {
	const store = window.booqableStore || window.store;
	let latestStartsAt = false;
	let latestStopsAt = false;
	let currentCouponCode = false;

	/* uses global store */
	function getCart() {
		const state = store.getState();
		const cartId = state.client ? state.client.cartId : false;
		return cartId ? state.orm.carts.getById(cartId) : state.orm.carts.getFirst();
	}

	/* uses global store */
	function onStoreDataChange() {
		const cart = getCart();
		if(!cart) {
			// booqable not initialized yet
			setTimeout(onStoreDataChange, 300);
			return;
		}
		const startsAt = cart.starts_at;
		const stopsAt = cart.stops_at;

		// this fn triggers relatively often, so check for date changes
		if(startsAt === latestStartsAt && stopsAt === latestStopsAt) {
			return;
		}
		latestStartsAt = startsAt;
		latestStopsAt = stopsAt;

		updateCouponCode(startsAt, stopsAt);
	}
	store.subscribe(onStoreDataChange);
	onStoreDataChange();

	function updateCouponCode(startsAt, stopsAt) {
		const start = moment.utc(startsAt);
		const end = moment.utc(stopsAt);

		const isTrailer = document.querySelector('.trailer-list');
		const isTruck = document.querySelector('[data-id="abschleppwagen"]');
		const startIsValidForLongWeekend = start.weekday() === 4 && start.hour() >= 17; // Thursday after 17:00
		const startIsValidForShortWeekend = start.weekday() === 5 && start.hour() >= 13; // Friday after 13:00
		const endsWithMondayBeforeOrAt9 = end.weekday() === 1 && (end.hour() < 9 || (end.hour() === 9 && end.minute() === 0)); // Monday before/at 9:00

		const diffInMilliseconds = end.diff(start);
		const diffInDays = diffInMilliseconds/1000/60/60/24;
		const isOneWeekend = diffInDays > 2 && diffInDays < 4;

		let identifier;
		let cssClass;
		if(isTrailer) {
			// this is a trailer
			if (startIsValidForLongWeekend && endsWithMondayBeforeOrAt9 && isOneWeekend) {
				identifier = 'Anhänger Wochenend-Rabatt - Do. 17 Uhr bis Mo. 9 Uhr';
				cssClass = 'has-trailer-weekend-discount-long';
			} else if (startIsValidForShortWeekend && endsWithMondayBeforeOrAt9 && isOneWeekend) {
				identifier = 'Anhänger Wochenend-Rabatt - Fr. 13 Uhr bis Mo. 9 Uhr';
				cssClass = 'has-trailer-weekend-discount-short';
			} else {
				identifier = '';
				cssClass = false;

				if (isOneWeekend && start.weekday() === 4) {
					cssClass = 'recommend-trailer-weekend-discount-long';
				} else if (isOneWeekend && start.weekday() === 5) {
					cssClass = 'recommend-trailer-weekend-discount-short';
				}
			}
		}
		if(isTruck) {
			// this is the truck
			if (startIsValidForShortWeekend && endsWithMondayBeforeOrAt9 && isOneWeekend) {
				identifier = 'Autotransporter Wochenend-Rabatt - Fr. 13 Uhr bis Mo. 9 Uhr';
				cssClass = 'has-truck-weekend-discount-short';
			} else {
				identifier = '';
				cssClass = false;

				if (isOneWeekend && start.weekday() === 5) {
					cssClass = 'recommend-truck-weekend-discount-short';
				}
			}
		}

		// set the coupon code on the body tag, so that we can show notices
		// with CSS (we shouldn't change the React dom directly, since we can't control rendering)
		const bodyClassList = document.querySelector('body').classList;
		bodyClassList.remove('has-trailer-weekend-discount-long');
		bodyClassList.remove('has-trailer-weekend-discount-short');
		bodyClassList.remove('recommend-trailer-weekend-discount-long');
		bodyClassList.remove('recommend-trailer-weekend-discount-short');
		bodyClassList.remove('has-truck-weekend-discount-short');
		bodyClassList.remove('recommend-truck-weekend-discount-short');
		if(cssClass) bodyClassList.add(cssClass);

		setCouponCode(identifier);
	}

	function setCouponCode(identifier) {
		if(identifier === currentCouponCode) {
			return; // nothing to do
		}
		currentCouponCode = identifier;
		const cartId = getCart().id;
		const options = {
			cart_id: cartId,
			identifier: identifier
		};

		axios.post('https://schuetz-wien.booqable.shop/api/1/cart/redeem_coupon', options).then(() => {
			// Will populate the state with new data
			const CartActions = (Model.default || Model).Cart.actions
			CartActions.fetch({ id: cartId });
		});
	}
}